import React, { PropsWithChildren, useCallback } from "react";
import { Button, InputProps, notification } from "antd";
import { InputPassword } from "../../../atoms/Inputs";

interface Props extends InputProps {
    copytoclipboard?: boolean | string;
}

const ShowPasswordInput: React.FC<PropsWithChildren<Props>> = ({
    value,
    placeholder,
    copytoclipboard,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const toggleVissible = useCallback(() => {
        setPasswordVisible((prevState) => !prevState);

        // copytoclipboard the text inside the text field
        if (!value || !copytoclipboard) return;
        if (passwordVisible) return;
        navigator.clipboard.writeText(String(value));
        api.open({
            message: `Value copied to clipboard`,
            type: "info",
            placement: "topRight",
            duration: 6,
        });

        setTimeout(() => setPasswordVisible(false), 6000);
    }, [passwordVisible, api, copytoclipboard, value]);

    return (
        <div style={{ display: "flex", columnGap: "1rem" }}>
            {contextHolder}
            <InputPassword
                placeholder={placeholder}
                visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: () => toggleVissible(),
                }}
                value={value}
            />
            <Button
                style={{ width: 80 }}
                onClick={() => {
                    toggleVissible();
                }}
            >
                {passwordVisible ? "Hide" : "Show"}
            </Button>
        </div>
    );
};

export default ShowPasswordInput;
