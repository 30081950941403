import { Button, Input, InputRef, Space, Typography } from "antd";
// import { Table } from "atoms/Table";
import type { ColumnType, ColumnsType, TableProps } from "antd/es/table";
import React, { useRef } from "react";
import { Table } from "../../atoms/Table";
import { SearchOutlined } from "@ant-design/icons";
import { FilterConfirmProps } from "antd/es/table/interface";
const { Text } = Typography;

type DataIndex = keyof FileDataType;

const FilesTable: React.FC<TableProps<FileDataType>> = (props) => {
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };
    const getColumnSearchProps = (
        dataIndex: DataIndex
    ): ColumnType<FileDataType> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);

                            handleSearch([], confirm, dataIndex);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? "#1677ff" : undefined }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                return (record[dataIndex] as string)
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase());
            }

            return false;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const columns: ColumnsType<FileDataType> = [
        {
            title: "UUID",
            dataIndex: "uuid",
            ellipsis: true,
            responsive: ["md"],
        },
        {
            title: "Name",
            dataIndex: "name",
            ellipsis: true,
            render: (_, record) => (
                <Space size="middle">
                    <Text>{_}</Text>
                </Space>
            ),
            ...getColumnSearchProps("name"),
        },
        {
            title: "IPFS",
            dataIndex: "ipfs",
            key: "ipfs",
            render: (_, record) => (
                <Space size="middle">
                    <a
                        href={`https://gateway.bakrypt.io/ipfs/${_}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        ipfs://{_}
                    </a>
                </Space>
            ),
            width: "50%",
            ...getColumnSearchProps("ipfs"),
        },
        {
            title: "Mimetype",
            key: "mimetype",
            dataIndex: "mimetype",
            width: 100,
            ellipsis: true,
            responsive: ["md"],
            ...getColumnSearchProps("mimetype"),
        },
        {
            title: "Created",
            key: "created_on",
            dataIndex: "created_on",
            width: 100,
            ellipsis: true,
            sorter: (a, b) => {
                const _a = new Date(a.created_on);
                const _b = new Date(b.created_on);

                return _a.getTime() - _b.getTime();
            },
            render: (value: string) => {
                return <>{new Date(value).toUTCString()}</>;
            },
        },
    ];

    return <Table columns={columns} dataSource={props.dataSource} />;
};

export default FilesTable;
