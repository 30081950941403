import React, { PropsWithChildren } from "react";
import logo from "./logo.svg";
import "./App.css";
import Button from "../../atoms/Button";

const App: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>
                <Button>
                    <a
                        className="App-link"
                        href="https://reactjs.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        learn react
                    </a>
                </Button>
            </header>
            {children}
        </div>
    );
};

export default App;
