import Input, { TextAreaProps } from "antd/es/input";

const TextArea: React.FC<TextAreaProps> = (props) => {
    const noChildrens = { ...props };

    // Fixes input is a void element tag and must neither have `children` nor use `dangerouslySetInnerHTML`.
    delete noChildrens["children"];
    return <Input.TextArea {...noChildrens} />;
};

export default TextArea;
