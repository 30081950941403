import React, { PropsWithChildren } from "react";
import { Button as DSButton } from "antd";
import { ButtonProps } from "antd/es/button/button";

const Button: React.FC<
    PropsWithChildren<ButtonProps>
> = (props) => {
    return <DSButton {...props} />;
};

export default Button;
