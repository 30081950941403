import React from "react";
import { InputProps, InputRef, Input as VendorInput } from "antd";


const Input: React.FC<InputProps & React.RefAttributes<InputRef>> = (props) => {
    const noChildrens = { ...props };

    // Fixes input is a void element tag and must neither have `children` nor use `dangerouslySetInnerHTML`.
    delete noChildrens["children"];

    return <VendorInput {...noChildrens} />;
};

export default Input;
