import { Header } from "antd/es/layout/layout";
import React, { PropsWithChildren } from "react";

const SimpleHeader: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Header style={{ display: "flex", alignItems: "center" }}>
            {children}
        </Header>
    );
};

export default SimpleHeader;
