import React from "react";
import CookieConsent from "react-cookie-consent";

const Consent: React.FC = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept Cookies"
            cookieName="BakCookieConsent"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            hideOnAccept={true}
            expires={1}
        >
            Cookies allows the web application to respond to you as an
            individual. The web application can tailor its operations to your
            needs, by gathering and remembering information about your session.
        </CookieConsent>
    );
};

export default Consent;
