import React, { useEffect, useState } from "react";
import { Table as VendorTable } from "antd";
import type { TableProps } from "antd/es/table";

const Table: React.FC<TableProps<any>> = (props) => {
    const [dataSource, setDataSource] = useState<
        readonly FileDataType[] | readonly TransactionDataType[]
    >([]);

    useEffect(() => {
        if (typeof props.dataSource === "string") {
            const jsonData = JSON.parse(props.dataSource);
            setDataSource(jsonData);
        }
    }, [props.dataSource]);

    return (
        <VendorTable
            {...props}
            columns={props.columns}
            dataSource={dataSource}
        />
    );
};

export default Table;
