import React from "react";
import { Input as VendorInput } from "antd";
import { PasswordProps } from "antd/es/input";

const PasswordInput: React.FC<PasswordProps> = (props) => {
    const noChildrens = { ...props };

    // Fixes input is a void element tag and must neither have `children` nor use `dangerouslySetInnerHTML`.
    delete noChildrens["children"];

    return <VendorInput.Password {...noChildrens} />;
};

export default PasswordInput;
