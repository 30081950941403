import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import parse from "html-react-parser";
import "./index.css";
import App from "./composites/App";
import Button from "./atoms/Button";
import Layout from "./composites/Layout";
import Divider from "./atoms/Divider";
import SampleForm from "./composites/SampleForm";
import SimpleHeader from "./composites/SimpleHeader";
import Menu from "./atoms/Menu";
import MenuItem from "./atoms/MenuItem";
import ShowPasswordInput from "./composites/Forms/Inputs/ShowPasswordInput";
import { Checkbox, Input, TextArea } from "./atoms/Inputs";
import { Table } from "./atoms/Table";
import { FilesTable, TransactionsTable } from "./composites/Tables";
import Consent from "./atoms/Consent/Consent";
import WalletConnector from "./atoms/Wallet";

const registerComponent = (
    Component: React.FC<PropsWithChildren>,
    ElementName: string
) => {
    // Create a unique class name based on the React component's name or label
    const customElementName = `${ElementName}`
        .replace(/([a-z])([A-Z])/g, "$1-$2") // Convert camelCase to kebab-case
        .toLowerCase();

    if (customElements.get(customElementName)) return;

    class ReactWrapperElement extends HTMLElement {
        connectedCallback() {
            if (!this.isConnected) return;

            const content = this.innerHTML;
            this.innerHTML = ""; // Remove content to avoid dups

            // Create an object to hold the props
            let props: {
                [key: string]: string | undefined | null | boolean | Function;
            } = {};

            // Iterate over the custom element's attributes
            for (const { name, value } of this.attributes) {
                // Convert attribute names to camelCase for props
                const propName = name.replace(/-([a-z])/g, (match, letter) =>
                    letter.toUpperCase()
                );
                // If the attribute doesn't have a value, assume it's a boolean attribute
                if (!["value", "defaultValue"].includes(propName))
                    props[propName] = value !== "" ? value : true;
                else {
                    props[propName] = value;
                }
            }

            const reactRoot = ReactDOM.createRoot(this);

            reactRoot.render(
                <React.StrictMode>
                    <Component {...props}>{parse(content)}</Component>
                </React.StrictMode>
            );
        }
    }

    customElements.define(`atomic-${customElementName}`, ReactWrapperElement);
};

registerComponent(App, "App");
registerComponent(Button, "Button");
registerComponent(Layout, "Layout");
registerComponent(Divider, "Divider");
registerComponent(SampleForm, "SampleForm");
registerComponent(SimpleHeader, "SimpleHeader");
registerComponent(Menu, "Menu");
registerComponent(MenuItem, "MenuItem");
registerComponent(Consent, "Consent");

registerComponent(ShowPasswordInput, "ShowPasswordInput");
registerComponent(Input, "Input");
registerComponent(TextArea, "TextArea");
registerComponent(Checkbox, "Checkbox");
registerComponent(Table, "Table");

registerComponent(FilesTable, "FilesTable");
registerComponent(TransactionsTable, "TransactionsTable");

registerComponent(WalletConnector, "WalletConnector");