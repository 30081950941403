import { Space, Switch, Tag, Popconfirm, Modal } from "antd";
import { Table } from "../../atoms/Table";
import { Input } from "../../atoms/Inputs";

import { SearchOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import Button from "../../atoms/Button";
import type { ColumnsType, TableProps, ColumnType } from "antd/es/table";
import React, { useEffect, useRef, useState } from "react";
import Divider from "../../atoms/Divider";
import type { FilterConfirmProps } from "antd/es/table/interface";
import BakBridge from "bakbridge";
import "bakbridge/dist/main.css";

type DataIndex = keyof TransactionDataType;

const renderColor: Function = (status: string) => {
    if (status === "confirmed") return "green";

    if (["canceled", "error", "rejected"].includes(status)) return "red";

    if (["processing", "stand-by"].includes(status)) return "blue";

    return "gold";
};

const TransactionsTable: React.FC<TableProps<TransactionDataType> & any> = (
    props
) => {
    const { csrftoken, testnet } = props;

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [loading, setLoading] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const showPopconfirm = () => {
        setOpenConfirm(true);
    };
    const [confirmLoading, setConfirmLoading] = useState(false);

    const hasSelected = selectedRowKeys.length > 0;

    const mintTransactions = () => {
        setLoading(true);
        const promises = selectedRowKeys.reduce(
            (acc: Promise<any>[], key, i) => {
                acc.push(
                    fetch(`/v1/transactions/${key}/mint/`, {
                        method: "POST",
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRFToken": csrftoken,
                        },
                    })
                );

                return acc;
            },
            []
        );

        Promise.allSettled(promises).then((results) => {
            results.forEach(async (result) => {
                await result;
            });
            setLoading(false);
            window.location.reload();
        });
    };

    const handleCancel = () => {
        console.log("Clicked cancel button");
        setOpenConfirm(false);
    };
    const cancelTransactions = () => {
        setConfirmLoading(true);
        setLoading(true);
        const promises = selectedRowKeys.reduce(
            (acc: Promise<any>[], key, i) => {
                acc.push(
                    fetch(`/v1/transactions/${key}/refund/`, {
                        method: "POST",
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRFToken": csrftoken,
                        },
                    })
                );

                return acc;
            },
            []
        );

        Promise.allSettled(promises).then((results) => {
            results.forEach(async (result) => {
                await result;
            });
            setLoading(false);
            setConfirmLoading(false);
            window.location.reload();
        });
    };

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex
    ): ColumnType<TransactionDataType> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);

                            handleSearch([], confirm, dataIndex);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? "#1677ff" : undefined }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                return (record[dataIndex] as string)
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase());
            }

            return false;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });
    const bridgeRef = useRef<HTMLDivElement>(null);
    const [activeUuid, setActiveUuid] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleUuidActivation = (uuid: string) => {
        setActiveUuid(uuid);
        setIsModalOpen(true);
    };

    const columns: ColumnsType<TransactionDataType> = [
        {
            title: "UUID",
            dataIndex: "uuid",
            ellipsis: true,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => handleUuidActivation(_)}>
                        {_}
                    </Button>
                </Space>
            ),
            ...getColumnSearchProps("uuid"),
        },
        {
            title: "Policy Id",
            dataIndex: "policy_id",
            ellipsis: true,
            responsive: ["md"],
            render: (_, record) => (
                <Space size="middle">
                    <a
                        href={`https://${
                            testnet ? "preprod." : ""
                        }cexplorer.io/policy/${_}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {_}
                    </a>
                </Space>
            ),
            ...getColumnSearchProps("policy_id"),
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (_, record) => (
                <Space size="middle">
                    <Tag color={renderColor(_)}>{_}</Tag>
                </Space>
            ),
            filters: [
                { text: "Burning", value: "burning" },
                { text: "Canceled", value: "canceled" },
                { text: "Confirmed", value: "confirmed" },
                { text: "Error", value: "error" },
                { text: "Pre Auth", value: "preauth" },
                { text: "Processing", value: "processing" },
                { text: "Refund", value: "refund" },
                { text: "Rejected", value: "rejected" },
                { text: "Royalties", value: "royalties" },
                { text: "Stand By", value: "stand-by" },
                { text: "Waiting", value: "waiting" },
            ],
            onFilter: (value, record) =>
                record.status.indexOf(String(value)) === 0,
        },
        {
            title: "Cost",
            dataIndex: "cost",
            responsive: ["md"],
            ellipsis: true,
        },
        {
            title: "Royalties",
            dataIndex: "has_royalties",
            responsive: ["md"],
            render: (_, record) => (
                <Space size="middle">
                    <Switch disabled={true} size="small" defaultChecked={_} />
                </Space>
            ),
            filters: [
                { text: "Yes", value: true },
                { text: "No", value: false },
            ],
            onFilter: (value, record) => value === record.has_royalties,
        },
        {
            title: "Refunded",
            dataIndex: "is_refunded",
            responsive: ["md"],
            render: (_, record) => (
                <Space size="middle">
                    <Switch disabled={true} size="small" defaultChecked={_} />
                </Space>
            ),
            filters: [
                { text: "Yes", value: true },
                { text: "No", value: false },
            ],
            onFilter: (value, record) => value === record.is_refunded,
        },
        {
            title: "Created",
            dataIndex: "created_on",
            ellipsis: true,
            sorter: (a, b) => {
                const _a = new Date(a.created_on);
                const _b = new Date(b.created_on);
                return _a.getTime() - _b.getTime();
            },
            render: (value: string) => {
                return <>{new Date(value).toUTCString()}</>;
            },
        },
    ];

    useEffect(() => {
        if (!bridgeRef.current || !activeUuid) return;
        
        new BakBridge({
            bakToken: "---",
            container: bridgeRef.current,
            transactionUuid: activeUuid,
            showTransaction: true,
            disableForm: true,
            client: {
                baseUrl: "/v1/",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRFToken": csrftoken,
                },
            },
        });
    }, [activeUuid, csrftoken]);

    const [showCanceled, setShowCanceled] = useState<boolean>(true);

    const hideCanceled = async (checked: boolean) => {
        const reloadPage = new URL(
            `${checked ? `?hidecanceled=1` : window.location.pathname}`,
            window.location.href
        );
        window.location.href = reloadPage.href;
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        setShowCanceled(url.search.includes("hidecanceled=1"));
    }, [setShowCanceled]);

    return (
        <div>
            <div style={{ display: "flex", columnGap: "0.8rem" }}>
                <Button
                    type="primary"
                    onClick={mintTransactions}
                    disabled={!hasSelected}
                    loading={loading}
                >
                    Mint
                </Button>
                <Popconfirm
                    title="Cancel selected transactions"
                    description="Are you sure?"
                    open={openConfirm}
                    onConfirm={cancelTransactions}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                >
                    <Button
                        type="dashed"
                        className="text-danger"
                        onClick={showPopconfirm}
                        disabled={!hasSelected}
                        loading={loading}
                    >
                        Cancel Transaction
                    </Button>
                </Popconfirm>
                <Space size={"small"}>
                    {hasSelected
                        ? `Selected ${selectedRowKeys.length} items`
                        : ""}
                </Space>
                <Switch
                    checked={showCanceled}
                    onChange={(checked: boolean) => hideCanceled(checked)}
                />
                Hide Canceled
            </div>
            <Divider />
            <Table
                rowKey="uuid"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={props.dataSource}
            />
            <Modal
                title="Review Transaction"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                closable={true}
                style={{ maxWidth: "100vw" }}
                width={800}
            >
                <div ref={bridgeRef}></div>
            </Modal>
        </div>
    );
};

export default TransactionsTable;
