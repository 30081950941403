import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    ConnectWalletButton,
    ConnectWalletButtonProps,
    useCardano,
} from "@cardano-foundation/cardano-connect-with-wallet";

import { Alert, message } from "antd";
import { CookiesProvider, useCookies } from "react-cookie";
import Link from "antd/es/typography/Link";

const WalletConnector: React.FC<
    PropsWithChildren & {
        isauthenticated?: string;
        csrftoken?: string;
    }
> = ({ isauthenticated, csrftoken = "" }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { isConnected, stakeAddress, installedExtensions, isConnecting } =
        useCardano();
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [cookies] = useCookies();
    const handleConnectError = () => {
        messageApi.open({
            type: "error",
            content: "Failed to connect wallet",
        });
    };
    const verifyWallet = useCallback(
        async (signature: string, key: string) => {
            messageApi.open({
                type: "loading",
                duration: 0,
                content: "Fetching user profile",
            });

            try {
                const userReq = await fetch(
                    `/v1/addresses/${stakeAddress}/verify/`,
                    {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRFToken": csrftoken,
                        },
                        body: JSON.stringify({ signature, key }),
                    }
                );

                if (!userReq.ok) throw Error("failed to verify wallet");

                window.location.href = "/account/";
            } catch (error) {
                messageApi.open({
                    type: "error",
                    content: "Failed to verify wallet",
                });
                console.error(error);
            }

            setTimeout(messageApi.destroy, 3000);
        },
        [stakeAddress, messageApi, csrftoken]
    );

    const buttonConfig: ConnectWalletButtonProps = useMemo(() => {
        if (isLogged) return { dAppName: "Bakrypt.io" };

        return {
            dAppName: "Bakrypt.io",
            message:
                "By accessing or using any or all of the Services, you expressly acknowledge that (i) you have read and understood these Terms; (ii) you agree to be bound by these Terms; and (iii) you are legally competent to enter into these Terms. You can find our terms and conditions in our website https://bakrypt.io/terms",
            onSignMessage: (signature, key) => {
                if (key) verifyWallet(signature, key);
            },
        };
    }, [isLogged, verifyWallet]);

    useEffect(() => {
        if (isConnecting)
            messageApi.open({
                type: "loading",
                content: "Wallet is connecting...",
            });

        if (isConnected) messageApi.destroy();
    }, [isConnecting, isConnected, messageApi]);

    useEffect(() => {
        if ("sessionid" in cookies || isauthenticated) setIsLogged(true);
    }, [cookies, isauthenticated]);

    return (
        <CookiesProvider defaultSetOptions={{ path: "/", secure: true }}>
            {contextHolder}
            <div
                style={{
                    maxWidth: 240,
                    minWidth: 180,
                }}
            >
                {!isLogged && isConnected ? (
                    <div className="mb-1">
                        <Alert
                            message="Sign message to verify account"
                            type="info"
                            showIcon
                        />
                    </div>
                ) : null}
                <ConnectWalletButton
                    {...buttonConfig}
                    onConnectError={handleConnectError}
                    onDisconnect={() =>
                        (window.location.href = "/account/logout")
                    }
                />
                <br />
                {!installedExtensions.length ? (
                    <small>
                        Install a{" "}
                        <Link
                            target="_blank"
                            href="https://developers.cardano.org/docs/integrate-cardano/creating-wallet-faucet/"
                        >
                            Cardano browser extension
                        </Link>
                    </small>
                ) : null}
            </div>
        </CookiesProvider>
    );
};

export default WalletConnector;
