import React, { useState } from "react";
import {
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
    TreeSelect,
} from "antd";
import TextArea from "antd/es/input/TextArea";

type SizeType = Parameters<typeof Form>[0]["size"];

const SampleForm: React.FC = () => {
    const [componentSize, setComponentSize] = useState<SizeType | "default">(
        "default"
    );

    const onFormLayoutChange = ({ size }: { size: SizeType }) => {
        setComponentSize(size);
    };

    return (
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            initialValues={{ size: componentSize }}
            onValuesChange={onFormLayoutChange}
            size={componentSize as SizeType}
            style={{ maxWidth: 600 }}
        >
            <Form.Item label="Form Size" name="size">
                <Radio.Group>
                    <Radio.Button value="small">Small</Radio.Button>
                    <Radio.Button value="default">Default</Radio.Button>
                    <Radio.Button value="large">Large</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Input">
                <Input />
            </Form.Item>
            <Form.Item label="Select">
                <Select>
                    <Select.Option value="demo">Demo</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="TreeSelect">
                <TreeSelect
                    treeData={[
                        {
                            title: "Light",
                            value: "light",
                            children: [{ title: "Bamboo", value: "bamboo" }],
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item label="Cascader">
                <Cascader
                    options={[
                        {
                            value: "zhejiang",
                            label: "Zhejiang",
                            children: [
                                { value: "hangzhou", label: "Hangzhou" },
                            ],
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item label="DatePicker">
                <DatePicker />
            </Form.Item>
            <Form.Item label="InputNumber">
                <InputNumber />
            </Form.Item>
            <Form.Item label="Switch" valuePropName="checked">
                <Switch />
            </Form.Item>
            <Form.Item label="Button">
                <Button>Button</Button>
            </Form.Item>
            <Form.Item label="TextArea">
                <TextArea>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Excepturi dolore inventore debitis earum enim, optio
                    adipisci illo blanditiis ab ad odio ea quasi. Ipsam alias
                    voluptatum id perspiciatis, nostrum ipsa.
                </TextArea>
            </Form.Item>
        </Form>
    );
};

export default SampleForm;
